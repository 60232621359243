import React, { useState } from "react";
import Cookies from "universal-cookie";

function Login({ setLogged }) {

	const [nome, setNome] = useState("");
	const [password, setPassword] = useState("");
const cookie = new Cookies();
// Mondrone
//LaGorgia

	const handleSubmit = (e) => {
		e.preventDefault();
		if (nome === "admin" && password === "Circolo2023") {
			cookie.set('token', "112358132134");
			setLogged(true);
		} else {
			alert("nome o password errati");
		}
	}

	
	return (
		<div className="flex items-center justify-center w-full h-[60vh]">
			<form onSubmit={(e)=>handleSubmit(e)} className="w-96">
				<div className="mb-6">
					<label
						for="email"
						className="block mb-2 text-sm font-medium text-gray-900 "
					>
						Nome utente
					</label>
					<input
						type="nome"
						id="nome"
						onChange={(e) => setNome(e.target.value)}
						value={nome}
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
						placeholder="nome utente"
						
					/>
				</div>
				<div className="mb-6">
					<label
						for="password"
						className="block mb-2 text-sm font-medium text-gray-900 "
					>
						Password
					</label>
					<input
						type="password"
						id="password"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
						
					/>
				</div>
				
				<button
					type="submit"
					className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
				>
					Accedi
				</button>
			</form>
		</div>
	);
}

export default Login;
