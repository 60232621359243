import React from "react";

function Balme() {
	return (
		<div className="grid md:grid-cols-2 grid-cols-1 gap-6 place-items-center md:px-24 px-12">
			<a  className="md:col-span-2 my-7"
				href="https://www.comune.balme.to.it/it-it/home"
				target="_blank"
				rel="noreferrer"
			>
				<h1 className="text-4xl font-smeibold hover:underline">
					BALME
				</h1>
			</a>
			<a
				href="https://www.comune.balme.to.it/Wcam/cmb/bessanese.jpg"
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="w-[500px] rounded-md shadow-lg"
					src="https://www.comune.balme.to.it/Wcam/cmb/bessanese.jpg"
					alt=""
				/>
			</a>
			<a
				href="https://www.comune.balme.to.it/Wcam/cmb/est.jpg"
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="w-[500px] rounded-md shadow-lg"
					src="https://www.comune.balme.to.it/Wcam/cmb/est.jpg"
					alt=""
				/>
			</a>
		</div>
	);
}

export default Balme;
