import React from "react";

function cinqueXmille() {
	return (
		<div className="flex flex-col items-center justify-center">
			<h1 className="text-center text-4xl  font-bold my-2">
				5x1000
			</h1>
			<div className="px-12 text-justify">
				<p>
					Il 5 per mille è una quota dell’IRPEF che lo Stato ripartisce su
					indicazione dei cittadini-contribuenti al momento della dichiarazione
					dei redditi e destinata alle organizzazioni non profit iscritte agli
					elenchi dell’Agenzia delle Entrate.
				</p>
				<p className="mt-4">
					Si può assegnare il 5x1000 al Circolo Mondronese semplicemente
					inserendo il codice fiscale del Circolo 97664510019 sulla propria
					dichiarazione dei redditi nell’apposito spazio.
				</p>
				<div className="mt-4">
					<a
						className="hover:underline text-blue-500 cursor-pointer"
						href="https://www.facebook.com/CircoloMondronese/videos/1394856234209366"
					>
						Video promozionale 5x1000 al Circolo
					</a>
				</div>
			</div>
				<img className="mt-4 w-[750px]	" src={require('../../Assets/5x1000.jpg')} alt="" />
		</div>
	);
}

export default cinqueXmille;
