import React from "react";

function Scarpalonga() {
	return (
		<div className="my-12 md:px-24 px-12">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				La Scarpalonga
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					<strong>La Scarpalonga</strong> è una gara di marcia alpina che si
					tiene ogni anno a Mondrone, nel mese di agosto. La prima edizione
					della manifestazione è del <strong>19 agosto 1975</strong>, per
					iniziativa di{" "}
					<strong>
						Giacomo Solero, Claudio Ferrea, Augusto Laurent e Franco Cerrato
					</strong>{" "}
					e da allora è diventata una tradizione estiva attesissima.
				</p>
				<p className="mt-7">
					Questa corsa, che attraversa i suggestivi paesaggi montani delle Valli
					di Lanzo, offre ai partecipanti un'opportunità unica di immergersi
					nella natura incontaminata e di godere delle bellezze del territorio.
					Il percorso è adatto sia agli atleti esperti che agli amatori, con
					tratti che si snodano tra boschi, prati fioriti e sentieri panoramici.
					Può essere svolta di corsa per la gara o semplicemente camminando per
					il gusto di partecipare ad un evento tipico mondronese. Ogni anno,
					l'evento attira un numero crescente di partecipanti e spettatori,
					contribuendo a valorizzare le tradizioni della zona.
				</p>
				<p className="mt-7">
					Il <strong>percorso originale</strong> si snodava a partire
					dall'inizio del paese, dalla cappella Madonna delle Grazie, sotto a
					Villa Ronco, per la strada provinciale. Qui i partecipanti giravano
					alla curva del Forno sulla mulattiera della Regina, verso il ponte
					della Gorgia, per poi attraversare il rio del Vallonetto e
					successivamente la Stura sul ponte detto del Pianard, risalendo poi la
					mulattiera che costeggia il campo sportivo fino allo stradone. Qui si
					infilava la strada delle Ville, attraversando il rio delle Combette,
					fino al rio Cevré e si ridiscendeva poi per mulattiera, dietro ai
					campi bocce dell'Albergo Regina, fino alla piazza De Pinedo e infine
					all'arrivo nel vicolo detto Via Roma, all'imbocco della Piazza.
				</p>
				<p className="mt-7">
					Negli anni il percorso ha subito delle{" "}
					<strong>necessarie variazioni</strong> dovendo adattarsi alle
					condizioni di praticabilità del territorio ma ha mantenuto ogni volta
					stabile la lunghezza di circa 4,5 Km. La partenza è stata più volte
					variata, dalla cappella Madonna delle Grazie sotto a Villa Ronco, al
					cartello di Mondrone, al campo giochi, mentre sempre è rimasto
					invariato il tratto che dall’ex Regina porta al sentiero per la
					Gorgia, con attraversamento dell'omonimo ponte, tutta la parte alla
					destra orografica della Stura sino al ponte del Pianard, risalita
					verso il campo di pallavolo, stradone e deviazione per il ponticello
					sul Rio delle Combette. Per alcune edizioni non potendo usare
					l'attraversamento perché impraticabile si risaliva sino al guado per
					riprendere il tratto, anche questo mai cambiato della strada delle
					ville e ridiscesa del sentiero a fianco del campo bocce sino alla
					Piazza.
				</p>
				<p className="mt-7">
					La Scarpalonga non è solo una gara sportiva, ma anche un momento di
					festa e condivisione per la comunità di Mondrone. Alla gara fa seguito
					la premiazione dei primi classificati per categoria maschile e
					femminile e il più ambito premio al primo assoluto, vincitore della
					gara. La Scarpalonga è un evento che celebra lo sport, la natura e la
					comunità, regalando a tutti i partecipanti un'esperienza
					indimenticabile nelle incantevoli Valli di Lanzo.
				</p>
			</div>
			<div className="flex flex-col md:grid md:grid-cols-4 gap-7 items-center justify-between mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga1.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga2.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga3.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga4.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga5.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga6.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga7.jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto per scarpalonga/scarpalonga8.jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default Scarpalonga;
