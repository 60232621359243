import React from "react";


function tagliaTestoDopoTotParole(testo, numParole) {
	var parole = testo.split(" ");

	if (parole.length > numParole) {
		return parole.slice(0, numParole).join(" ") + "...";
	}

	return testo;
}


function Fissata({data, id}) {
	const descr = data.descr;
	return (
		<div>
			<div className="md:w-full w-screen my-7 h-28 bg-white flex rounded-lg shadow">
				<a href={'/news/'+id}>
					<img
						className="rounded-t-lg h-full  object-cover"
						src={data.img}
						alt=""
					/>
				</a>
				<div className="flex flex-col justify-center pl-4">
					<a href={'/news/'+id}>
						<h5 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 ">
							{data.titolo}
						</h5>
					</a>
					<p className="mb-1 font-normal text-sm text-gray-700 dark:text-gray-400">
						{tagliaTestoDopoTotParole(descr,10)}
					</p>
					<a
						href={'/news/'+id}
						className="inline-flex items-center p-2 w-28 text-xs font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
					>
						Leggi di più
						<svg
							aria-hidden="true"
							className="w-4 h-4 ml-2 -mr-1"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
								clip-rule="evenodd"
							></path>
						</svg>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Fissata;
