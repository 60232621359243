import React, { useState } from "react";
import ReloadBtn from "../../Components/Atoms/Buttons/ReloadBtn";
import NormalBtn from "../../Components/Atoms/Buttons/NormalBtn";
import bruno from "../../Assets/piazza.jpeg";
import mondrone1 from "../../Assets/webcams/webcam1.jpeg";
import mondrone2 from "../../Assets/webcams/webcam2.jpeg";
import mondrone3 from "../../Assets/webcams/webcam3.jpeg";
import mondrone4 from "../../Assets/webcams/webcam4.jpeg";
import HoverWbcm from "../../Components/Atoms/Hover/HoverWbcm";

function Webcam() {
	const [hover, setHover] = useState(-1);
	const proxyURL = "https://cors-anywhere.herokuapp.com/";
	const camMondrone = [
		{
			//url: "http://194.116.5.129:85/videostream.cgi?user=ospiti&pwd=fateibravi",
			url: "http://194.116.5.129:85/cgi-bin/CGIStream.cgi?cmd=GetMJStream&usr=ospiti&pwd=fateibravi1",
			img: bruno,
		},
		{
			url: "http://160.202.30.132:84/cgi-bin/CGIProxy.fcgi?cmd=snapPicture2&usr=visitatore&pwd=gatto",
			img: mondrone1,
		},
		{
			url: "http://160.202.30.132:83/cgi-bin/CGIProxy.fcgi?cmd=snapPicture2&usr=visitatore&pwd=gatto",
			img: mondrone2,
		},
		{
			url: "http://160.202.30.132:85/cgi-bin/CGIProxy.fcgi?cmd=snapPicture2&usr=visitatore&pwd=gatto",
			img: mondrone4,
		},
		{
			url: 'http://ospiti:fateibravi@194.116.5.129:83/image/jpeg.cgi',
			img: mondrone3,
		},
	];

	return (
		<div className="flex flex-col items-center justify-center">
			<h1 className="place-self-center text-6xl font-semibold uppercase my-4">
				Webcam
			</h1>

			<h2 className="text-2xl uppercase font-semibold ">Mondrone</h2>
			<div className="flex flex-wrap justify-center items-center">
				{camMondrone.map((item, i) => {
					return (
						<div
							className="m-7 relative"
							onMouseEnter={() => setHover(i)}
							onMouseLeave={() => setHover(-1)}
						>
							{hover === i && <HoverWbcm href={item.url} />}
							<a href={item.url} target="_blank" rel="noreferrer">
								<img
									className="w-[500px] rounded-lg shadow-lg"
									src={item.img}
									alt=""
								/>
							</a>
						</div>
					);
				})}
				<div className="m-7 relative ">
					<a
						className="cursor-default"
						href="#"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[500px] rounded-lg shadow-lg"
							src={require("../../Assets/panchina.jpg")}
							alt=""
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Webcam;
