import { useScroll, useTransform, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ChipTabs from "../../Components/Molecules/Navabr/ChipTabs";
import Griglieria from "../../Components/Molecules/Griglie/Griglieria";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const ImageGridHero = () => {
	const tabs = ["Paese", "Storica", "Circolo"];
    const targetRef = useRef(null);
    const [Data, setData] = useState([]);
    const [selected, setSelected] = useState(tabs[0]);
    const [loaded, setLoaded] = useState(false);

    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const slug = window.location.href.split("#")[1];
    useEffect(() => {
        if (slug) {
            const scrolla = document.getElementById("vetrina");
            setSelected(slug);
            window.scrollTo({ top: scrolla.offsetTop, behavior: "smooth" });
        }
		handleLoad()
    }, []);

    const handleLoad = async () => {
        const querySnapshot = await getDocs(collection(db, "images"));
        const docs = querySnapshot.docs;
        setData(docs.map((doc) => doc.data()));
        console.log(docs.map((doc) => doc.data()));
        setLoaded(true);
    };


    return (
        <>
            <section ref={targetRef} className="bg-white h-[350vh]">
                <div className="h-screen sticky top-0 z-0 grid grid-cols-3 grid-rows-3 gap-4 p-4 overflow-hidden">
                    <Copy scrollYProgress={scrollYProgress} />
                    <Images scrollYProgress={scrollYProgress} />

                    <Circles />
                </div>
            </section>
            {loaded && (
                <section
                    id="vetrina"
                    className="flex flex-col items-center justify-center"
                >
                    <ChipTabs
                        tabs={tabs}
                        setSelected={setSelected}
                        selected={selected}
                    />
                    <Griglieria
                        title={"Galleria " + selected}
                        foto={Data}
                        tab={selected}
                    />
                </section>
            )}
        </>
    );
};

const Copy = ({ scrollYProgress }) => {
    const copyScale = useTransform(scrollYProgress, [0, 0.75], [1, 0.5]);
    const copyOpacity = useTransform(scrollYProgress, [0, 0.75], [1, 0]);
    const copyY = useTransform(scrollYProgress, [0, 0.75], ["0%", "7.5%"]);

    return (
        <motion.div
            style={{
                scale: copyScale,
                opacity: copyOpacity,
                y: copyY,
            }}
            className="absolute px-8 w-full h-screen z-20 flex flex-col items-center justify-center"
        >
            <h1 className="text-stone-950 text-5xl md:text-7xl font-bold text-center max-w-xl">
                GALLERIA
            </h1>
            <p className="text-stone-600 text-sm md:text-base text-center max-w-xl my-6">
                Tutte le foto del nostro archivio, <br /> dal XIII secolo fino a
                oggi.
            </p>
        </motion.div>
    );
};

const Images = ({ scrollYProgress }) => {
    const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

    const image1Offset = useTransform(scrollYProgress, [0, 1], ["-35%", "0%"]);

    const image2OffsetX = useTransform(scrollYProgress, [0, 1], ["30%", "0%"]);
    const image2OffsetY = useTransform(scrollYProgress, [0, 1], ["-30%", "0%"]);

    const image3OffsetX = useTransform(scrollYProgress, [0, 1], ["-25%", "0%"]);
    const image3OffsetY = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);

    const image4OffsetX = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);
    const image4OffsetY = useTransform(
        scrollYProgress,
        [0, 1],
        ["-145%", "0%"]
    );

    const image5OffsetX = useTransform(scrollYProgress, [0, 1], ["-25%", "0%"]);
    const image5OffsetY = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);

    const image6OffsetX = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);
    const image6OffsetY = useTransform(scrollYProgress, [0, 1], ["25%", "0%"]);

    return (
        <>
            <motion.div
                className="col-span-2 relative z-10"
                style={{
                    backgroundImage:
                        "url(https://mondrone-615c7.web.app/static/media/primavera.be0b3fe77a049ee18a38.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    scale,
                    x: image1Offset,
                    y: image1Offset,
                }}
            />
            <motion.div
                className="row-span-2 relative z-10"
                style={{
                    backgroundImage:
                        "url(https://mondrone-615c7.web.app/static/media/chiesa.420bc09201e89726d1b2.jpeg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    scale,
                    x: image2OffsetX,
                    y: image2OffsetY,
                }}
            />

            <motion.div
                className="row-span-2 relative z-10"
                style={{
                    backgroundImage:
                        "url(https://mondrone-615c7.web.app/static/media/peonia%20peregrina.a0c361c6a1411ecc9b5c.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    scale,
                    x: image3OffsetX,
                    y: image3OffsetY,
                }}
            />
            <motion.div
                className="relative z-10"
                style={{
                    backgroundImage:
                        "url(https://mondrone-615c7.web.app/static/media/scrittaMONDRONE.8337b378b3a2111fbdfb.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    scale,
                    x: image4OffsetX,
                    y: image4OffsetY,
                }}
            />

            <motion.div
                className="relative z-10"
                style={{
                    backgroundImage:
                        "url(https://mondrone-615c7.web.app/static/media/4.10f4d61a58554241399b.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    scale,
                    x: image5OffsetX,
                    y: image5OffsetY,
                }}
            />
            <motion.div
                className="relative z-10"
                style={{
                    backgroundImage:
                        "url(https://firebasestorage.googleapis.com/v0/b/mondrone-615c7.appspot.com/o/news%2F1690920896058panchina%20e%20uja.jpg?alt=media&token=44e0b0d2-9936-48b8-83f9-06ac236f50ba)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    scale,
                    x: image6OffsetX,
                    y: image6OffsetY,
                }}
            />
        </>
    );
};

const Circles = () => (
    <>
        <div className="w-3/5 max-w-[850px] min-w-[400px] aspect-square border-[8px] border-slate-200 rounded-full absolute z-0 left-0 top-0 -translate-x-[50%] -translate-y-[50%]" />
        <div className="w-1/2 max-w-[600px] min-w-[300px] aspect-square border-[8px] border-slate-200 rounded-full absolute z-0 right-0 bottom-0 translate-x-[50%] translate-y-[50%]" />
    </>
);

export default ImageGridHero;

const foto = [
    [
        "04 campanula elatines.jpg",
        "5x1000_old.jpg",
        "5x1000.jpg",
        "acquedotto.jpg",
        "Al_ba_percorso.png",
        "aquila.jpg",
    ],
    [
        "logo-circolo.jpg",
        "logoEsteso.jpg",
        "neve2.jpg",
        "panchina.jpg",
        "panorama.jpg",
        "peonia peregrina.jpg",
        "piazza.jpeg",
        "polentata5.jpg",
    ],
    ["tabellone.jpg", "vallata.jpg", "vallataT.png", "vitaassociativa.jpg"],
];
