import React from "react";

function Cirie() {
	return (
		<div className="grid md:grid-cols-2 grid-cols-1 gap-6 place-items-center md:px-24 px-12">
			<h1 className="md:col-span-2 text-4xl mb-1 font-semibold">
				Piano della Mussa
			</h1>
			<a
				className="md:col-span-2 mb-7"
				href="https://www.rifugiocirie.eu/"
				target="_blank"
				rel="noreferrer"
			>
				<h2 className="text-3xl uppercase hover:underline">rifugio Cirié</h2>
			</a>
			<a
				href="https://www.rifugiocirie.eu/webcam/index.php"
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="w-[500px] rounded-md shadow-lg"
					src="https://www.rifugiocirie.eu/webcam/index.php"
					alt=""
				/>
			</a>
			<a
				href="https://www.rifugiocirie.eu/webcam2/index2.php"
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="w-[500px] rounded-md shadow-lg"
					src="https://www.rifugiocirie.eu/webcam2/index2.php"
					alt=""
				/>
			</a>
		</div>
	);
}

export default Cirie;
