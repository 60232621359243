import React from "react";

function WebcamBTN() {
	const proxyURL = "https://cors-anywhere.herokuapp.com/";

	return (
		<div className="flex flex-col place-items-center md:px-24 ">
			<h1 className="uppercase place-self-center text-6xl font-semibold my-4">Webcam</h1>
			<div className="flex space-x-10 justify-center items-center ">
				<h2 className="text-2xl uppercase font-semibold ">
					Altri paesi e rifugi
				</h2>
			</div>
			<div
				id="altrewebcam"
				className="flex flex-wrap  justify-center items-center "
			>
				<div className="m-7">
					{" "}
					<h4 className="mb-1 font-sembold text-xl">Ala di Stura</h4>
					<a
						href="https://www.ilsalottodelletrew.com/webcam/webcam.jpg"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video rounded-md shadow-lg"
							src="https://www.ilsalottodelletrew.com/webcam/webcam.jpg"
							alt=""
						/>
					</a>
				</div>
				<div className="m-7">
					<h4 className="mb-1 font-sembold text-xl">Balme</h4>
					<a
						href="https://www.comune.balme.to.it/Wcam/cmb/bessanese.jpg"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video rounded-md shadow-lg"
							src="https://www.comune.balme.to.it/Wcam/cmb/bessanese.jpg"
							alt=""
						/>
					</a>
				</div>

				<div className="m-7">
					<h4 className="mb-1 font-sembold text-xl">Rifugio Cirié</h4>
					<a
						href="https://www.rifugiocirie.eu/webcam/index.php"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video rounded-md shadow-lg"
							src="https://www.rifugiocirie.eu/webcam/index.php"
							alt=""
						/>
					</a>
				</div>
				<div className="m-7">
					<a href="https://www.agrimasina.com/">
						<h4 className="mb-1 font-sembold text-xl hover:underline">
							La Masinà
						</h4>
					</a>
					<a
						href="https://www.agrimasina.com/:21/masina.jpg"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video rounded-md shadow-lg"
							src="https://www.agrimasina.com/:21/masina.jpg"
							alt=""
						/>
					</a>
				</div>

				<div className="m-7">
					<h4 className="mb-1 font-sembold text-xl">Chialamberto</h4>
					<a
						href="https://webcam.erre-elle.net/chialamberto/snapshots-cam1.jpg"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video rounded-md shadow-lg"
							src="https://webcam.erre-elle.net/chialamberto/snapshots-cam1.jpg"
							alt=""
						/>
					</a>
				</div>
				<div className="m-7">
					<h4 className="mb-1 font-sembold text-xl">Pian Benot</h4>
					<a
						href="https://www.pianbenot.it/MOBOTIX/test.jpg"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video rounded-md shadow-lg"
							src="https://www.pianbenot.it/MOBOTIX/test.jpg"
							alt=""
						/>
					</a>
				</div>
				<div className="m-7">
					<a
						href="/gastaldi"
						className="mb-2 font-sembold text-xl hover:underline"
					>
						Rifugio Gastaldi
					</a>
					<a
						href="https://bessanese.panomax.com"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="w-[300px] aspect-video object-contain"
							src={require("../../Assets/gastaldi.jpg")}
							alt=""
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default WebcamBTN;
