import React, { useState } from "react";
import XCard from "../../Components/Molecules/Card/XCard";
import Sentiero from "../../Components/Molecules/Sentieri/Sentiero";

function Al_Ba() {
	const [clicked, setClicked] = useState(0);
	const XCards = [
		{
			img: "Al_ba_percorso.png",
			titolo: "Il Sentiero",
			link: "https://www.openstreetmap.org/#map=14/45.3084/7.2500&layers=Y",
			descrizione:
				"Il Sentiero Natura AL-BA permette di percorrere la Valle di Ala, dal comune di Ala di Stura a quello di Balme, costeggiando e attraversando più volte il terrente Stura, e godendo di boschi, prati e radure per un’immersione totale nel verde e nella pace della natura e delle montagne. Realizzato intorno al 2006 dalla Comunità Montana Valli di Lanzo con l'obiettivo di creare un collegamento invernale tra Ala di Stura e Balme/Pian della Mussa con l’utilizzo delle ciaspole o sci da fondo e successivamente andato in disuso a causa di innevamenti non adeguati soprattutto nella prima parte del percorso, il sentiero è stato recuperato nella primavera del 2021 con i coinvolgimento dei Sindaci di Ala e di Balme, del C.A.I della Sezione di Lanzo e di Ala di Stura, della Squadra dei volontari Incendi Boschivi – A.I.B. di Ala di Stura, del Circolo Mondronese e con il contributo del Rotary di Ciriè e dalla Ditta Incasso di Ceretta di S. Maurizio Canavese. ",
		},
		{
			img: "SENTIEROALBAaccessibilità.jpg",
			titolo: "Accessibilità e segnalazione",
			link: "",
			descrizione:
				"Facilmente accessibile da famiglie, escursionisti, amanti della mountain bike o da appassionati di equitazione, il sentiero è particolarmente fruibile nella stagione estiva ed è accuratamente segnalato. Lungo il percorso sono posizionate frecce segnaletiche e bacheche informative che corrispondono ai punti di accesso e di uscita dell'itinerario principale. ",
		},
		{
			img: "SENTIEROALBAbellezzenaturali.jpg",
			titolo: "Bellezze naturali lungo il percorso",
			link: "",
			descrizione:
				"Il Sentiero Natura AL-BA offre ai frequentatori una straordinaria bellezza naturalistica, sviluppandosi in circa 7,0 Km con un dislivello di circa 360 mt. Il percorso si snoda totalmente lungo il torrente Stura di Lanzo ed è collegato ad una cospicua rete di sentieri che offrono al visitatore infinite possibilità di escursioni aggiuntive e con difficoltà differenti. Il torrente caratterizza il percorso ma non è l’unica attrattiva, infatti, con due brevi deviazioni di percorso, si possono ammirare le cascate della Gorgia di Mondrone e della Gorgia di Balme.  Il sentiero inizia sul territorio del Comune di Ala di Stura dalla frazione Villar località Montanara e lungo il tragitto consente di visitare altre borgate e frazioni caratteristiche come la borgata Tomà di Martassina, la frazione Cresto, fino alla frazione Mondrone. Da qui seguendo le frecce ed i paletti segnaletici, passando sul percorso che passa dietro al cimitero storico, attraversato il Campo Sportivo in erba e lasciandosi a sinistra l’area di gioco per i bambini, si sale verso la strada provinciale e dopo averla costeggiata ed aver attraversato il terrente Stura, ci si trova immersi in un bosco di larici di rara bellezza. Si prosegue e dopo aver attraversato nuovamente il torrente Stura, si entra in un bosco al di sotto della frazione Molette di Balme, altro caratteristico agglomerato di case con la sua bellissima e restaurata Cappella a Maria Maddalena. Si prosegue arrivando alla frazione Chialambertetto e dopo aver attraversato il ponte sulla Stura e la strada provinciale si prosegue ancora arrivando ai Cornetti, ultima frazione prima dell’arrivo nel Comune di Balme. ",
		},
	];

	return (
		<div className="grid md:grid-cols-2 grid-cols-1 md:px-10">
			<div>
				<h1 className="text-4xl text-center md:text-left font-semibold my-5">Sentiero Al-Ba</h1>
				<div className="flex flex-col px-5 space-y-3">
					{XCards.map((item, i) => {
						return (
							<XCard
								index={i}
								img={item.img}
								titolo={item.titolo}
								descrizione={item.descrizione}
								clicked={clicked}
								setClicked={setClicked}
							/>
						);
					})}
				</div>
			</div>
			<div className="mt-5">
				{XCards.map((item, i) => {
					if (i === clicked) {
						return (
							<Sentiero
								img={item.img}
								titolo={item.titolo}
								descrizione={item.descrizione}
								link={item.link}
							/>
						);
					}
				})}
			</div>
		</div>
	);
}

export default Al_Ba;
