import React from "react";

function NormalBtn({ title, href, sfondo, flag }) {
	const flags = flag ? false : true;

	let backgroundColorClass;
	let hoverBackgroundColorClass;

	switch (sfondo) {
		case "blue":
			backgroundColorClass = "bg-blue-500";
			hoverBackgroundColorClass = "hover:bg-blue-800";
			break;
		case "green":
			backgroundColorClass = "bg-green-700";
			hoverBackgroundColorClass = "hover:bg-green-800";
			break;
		default:
			backgroundColorClass = "bg-gray-700";
			hoverBackgroundColorClass = "hover:bg-gray-800";
			break;
	}

	return (
		<div>
			<a
				href={href}
				target={flags ? "_blank" : "_self"}
				rel={flags ? "noreferrer" : ""}
				className={`inline-flex w-auto md:w-auto items-center justify-between md:p-2 p-1 my-1 text-[10px] font-medium text-center text-white md:text-base ${backgroundColorClass} rounded-lg ${hoverBackgroundColorClass}`}
			>
				<p className="md:whitespace-nowrap">{title}</p>
				<svg
					aria-hidden="true"
					className="w-4 h-4 ml-2 -mr-1 hidden md:block"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
						clipRule="evenodd"
					></path>
				</svg>
			</a>
		</div>
	);
}

export default NormalBtn;
