import React from "react";

function MontagnaInCampo() {
	return (
		<div className="my-12 md:px-24 px-12">
			<h1 className="text-center text-3xl md:text-5xl uppercase font-semibold my-4">
				Il progetto "Montagna in Campo”
			</h1>
			<div className="text-justify">
				<div className="flex md:flex-row flex-col gap-7 mt-7 items-center">
					<div>
						<p className="mt-7">
							Il progetto "Montagna in Campo”, realizzato con il contributo
							della Fondazione Compagnia di San Paolo e presentato dalla società
							sportiva Noi di Valle in collaborazione con il Comune di Ala di
							Stura e il Circolo Mondronese ha mirato a migliorare la fruibilità
							e la funzionalità dell’
							<a
								className="hover:underline text-blue-500 cursor-pointer"
								href="/spaziaggregativi/areasportiva"
							>
								area sportiva
							</a>{" "}
							già presente nella frazione.
						</p>
						<p className="mt-7">
							Mondrone ha sempre avuto un forte legame con l’attività all'aria
							aperta e in particolare con l’attività sportiva. La piastra in
							cemento polifunzionale, costruita diversi anni fa ed esposta alle
							intemperie del clima di montagna soprattutto nella stagione
							invernale, necessitava di una ristrutturazione per poter
							accogliere al meglio le esigenze degli sportivi e dei
							frequentatori del campetto. L'idea del progetto Montagna in Campo
							è nata dunque dalla volontà di migliorare lo spazio rendendolo un
							luogo ideale per praticare diverse discipline sportive come il
							basket, la pallavolo e il{" "}
							<a
								className="hover:underline text-blue-500 cursor-pointer"
								href="/bidone"
							>
								bidone
							</a>{" "}
							e per favorire la socializzazione e il benessere fisico delle
							persone. Questo intervento, infatti, mira non solo a riqualificare
							lo spazio, ma anche a rafforzare il senso di comunità e offrire
							nuove opportunità di svago ai visitatori.
						</p>
					</div>
					<img
						className="max-w-full md:h-72"
						src={require("../../Assets/pre-getto.jfif")}
						alt=""
					/>
				</div>
				<p className="mt-7">
					Gli interventi di riqualificazione della piastra polifunzionale hanno
					seguito un piano ben strutturato, che ha coinvolto diverse fasi:
				</p>
				<ul className="pallino">
					<li className="pallinoli">
						Rifacimento della pavimentazione del campetto sportivo: la piastra
						in cemento è stata ricoperta da una nuova gettata di cemento che è
						stata poi livellata e resa idonea alla pratica sportiva, garantendo
						una maggiore sicurezza e comfort per chi gioca.
					</li>
					<li className="pallinoli">
						Realizzazione di scalini di cemento: per facilitare l’accesso dal
						lato dei gradoni in pietra sono stati realizzati degli scalini
					</li>
					<li className="pallinoli">
						Installazione di nuova recinzione del campo: è stata sostituita la
						rete di contenimento per evitare la dispersione dei palloni da gioco
					</li>
					<li className="pallinoli">
						Nuova segnaletica e delimitazione degli spazi: sono state realizzate
						nuove linee di demarcazione per le diverse attività sportive, tra
						cui campi da basket, pallavolo e bidone, rendendo lo spazio
						versatile e ben organizzato.
					</li>
					<li className="pallinoli">
						Installazione di attrezzature: sono stati installati nuovi canestri
						e ripristinati i pali per la rete da pallavolo
					</li>
				</ul>
				<p>
					La nuova piastra polifunzionale permetterà l'organizzazione di tornei
					e manifestazioni e la creazione di nuove occasioni di divertimento e
					aggregazione soprattutto nel periodo estivo.
				</p>
				<div className=" flex md:flex-row flex-col gap-7 my-7 items-center justify-around">
					<div className="flex flex-col items-center justify-center">
						<img
							className="max-w-full md:h-80"
							src={require("../../Assets/prima_campo.jfif")}
							alt=""
						/>
						<p>prima</p>
					</div>
					<div className="flex flex-col items-center">
						<img
							className="max-w-full md:h-80"
							src={require("../../Assets/dopo_campo.jfif")}
							alt=""
						/>
						<p>dopo</p>
					</div>
				</div>

				<p className="mt-10">
					Il progetto Montagna in Campo a Mondrone rappresenta un esempio
					virtuoso di come la riqualificazione di uno spazio sportivo possa
					portare benefici tangibili a una comunità. Grazie a interventi mirati
					e alla collaborazione tra enti locali e cittadini, la piastra in
					cemento polifunzionale diventa un punto di riferimento per lo sport e
					la socializzazione, contribuendo a migliorare la qualità della vita
					nella frazione e dimostrando che è possibile trasformare le
					infrastrutture esistenti in risorse preziose per il benessere
					collettivo.
				</p>
			</div>
			<div className="flex flex-col md:flex-row gap-7 items-center justify-around mt-10">
				<div className="">
					<img
						className="max-w-full md:h-96"
						src={require("../../Assets/nuovi canestri.jfif")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default MontagnaInCampo;
