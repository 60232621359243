// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
	apiKey: "AIzaSyC5LN4hLtTAyUKk2cnAeq7upm_bY599_qE",
	authDomain: "mondrone-615c7.firebaseapp.com",
	projectId: "mondrone-615c7",
	storageBucket: "mondrone-615c7.appspot.com",
	messagingSenderId: "1079651334032",
	appId: "1:1079651334032:web:5dc56349099a8508356515",
	measurementId: "G-KYV44MSMGC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);

