import React, { useEffect, useState } from "react";
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
    uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../firebase";
import {
    collection,
    addDoc,
    getDocs,
    deleteDoc,
    doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import IsLogged from "./IsLogged";

function Foto() {
    IsLogged();
    if (!IsLogged()) {
        window.location.href = "/admin";
    }

    const [file, setFile] = useState(null);
    const [Data, setData] = useState([]);
    const [ID, setID] = useState([]);
    const [canc, setCanc] = useState(-1);

    const upload = (e, type) => {
		if (!file) {
			return;
		}
        const name = Date.now() + file.name;
        e.preventDefault();
        const storageRef = ref(storage, "images/" + type + "/" + name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",

            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                        console.log("Upload is unknown");
                        break;
                }
            },
            (error) => {
                switch (error.code) {
                    case "storage/unauthorized":
                        break;
                    case "storage/canceled":
                        break;

                    case "storage/unknown":
                        break;
                    default:
                        console.log("General Error");
                        break;
                }
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("File available at", downloadURL);
                    addDoc(collection(db, "images"), {
                        url: downloadURL,
                        name: file.name,
                        type: type,
                        date: Date.now(),
                    });
                    handleLoad();
                });
            }
        );
    };

    const handleLoad = async () => {
        const querySnapshot = await getDocs(collection(db, "images"));
        const docs = querySnapshot.docs;
        setID(docs.map((doc) => doc.id));
        setData(docs.map((doc) => doc.data()));
        console.log(docs.map((doc) => doc.data()));
    };

    const elimina = async (i) => {
        await deleteDoc(doc(db, "images", ID[i]));
        handleLoad();
        setCanc(-1);
    };

    useEffect(() => {
        handleLoad();
    }, []);

    return (
        <div className="mt-10">
            <div className="p-20 absolute">
                <button
                    onClick={() => window.history.back()}
                    type="button"
                    class="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                >
                    Torna indietro
                </button>
            </div>
            <div className="flex flex-col w-full min-h-[80vh] space-y-20  items-center">
                <div className="flex flex-col space-y-5">
                    <label
                        class="block mb-2  text-sm font-medium text-gray-900 "
                        for="file_input"
                    >
                        Carica Foto
                    </label>
                    <input
                        onChange={(e) => setFile(e.target.files[0])}
                        className="block w-full text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="file_input"
                        type="file"
                    ></input>

                    <div className="flex justify-stretch w-full">
                        <button
                            onClick={(e) => upload(e, "paese")}
                            type="button"
                            class="text-white w-28 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                        >
                            Carica Paese
                        </button>
                        <button
                            onClick={(e) => upload(e, "storica")}
                            type="button"
                            class="text-white w-28 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                        >
                            Carica Storica
                        </button>
                        <button
                            onClick={(e) => upload(e, "circolo")}
                            type="button"
                            class="text-white w-28 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                        >
                            Carica Circolo
                        </button>
                    </div>
                </div>
                <div className="flex flex-col items-center space-y-2">
                    <p>Paese</p>
                    <div className="flex flex-wrap ">
                        {Data.map((item, i) => {
                            if (item.type === "paese") {
                                return (
                                    <div className="relative m-4 w-44 ">
                                        <img
                                            className=" bg-gray-200 aspect-square object-contain"
                                            src={item.url}
                                            alt=""
                                        />
                                        <div
                                            onClick={() => setCanc(i)}
                                            className="absolute cursor-pointer top-2 left-2 text-3xl font-bold text-red-500"
                                        >
                                            X
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <h1 className="p-5 text-black text-lg">Storica</h1>
                    <div className="flex flex-wrap ">
                        {Data.map((item, i) => {
                            if (item.type === "storica") {
                                return (
                                    <div className="relative m-4 w-44 ">
                                        <img
                                            className=" bg-gray-200 aspect-square object-contain"
                                            src={item.url}
                                            alt=""
                                        />
                                        <div
                                            onClick={() => setCanc(i)}
                                            className="absolute cursor-pointer top-2 left-2 text-3xl font-bold text-red-500"
                                        >
                                            X
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <p>Circolo</p>
                    <div className="flex flex-wrap ">
                        {Data.map((item, i) => {
                            if (item.type === "circolo") {
                                return (
                                    <div className="relative m-4 w-44 ">
                                        <img
                                            className=" bg-gray-200 aspect-square object-contain"
                                            src={item.url}
                                            alt=""
                                        />
                                        <div
                                            onClick={() => setCanc(i)}
                                            className="absolute cursor-pointer top-2 left-2 text-3xl font-bold text-red-500"
                                        >
                                            X
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>

                {canc > -1 && (
                    <div>
                        <div
                            id="popup-modal"
                            tabindex="-1"
                            class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto max-h-full"
                        >
                            <div class="relative w-full max-w-md max-h-full">
                                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <button
                                        onClick={() => setCanc(-1)}
                                        type="button"
                                        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                        data-modal-hide="popup-modal"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            class="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                    <div class="p-6 text-center flex flex-col items-center w-full">
                                        <img
                                            src={Data[canc].url}
                                            alt=""
                                            className="w-48"
                                        />
                                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                            Sicuro di cancellare questa foto?
                                        </h3>
                                        <div>
                                            <button
                                                onClick={() => elimina(canc)}
                                                data-modal-hide="popup-modal"
                                                type="button"
                                                class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                            >
                                                Si, sono sicuro
                                            </button>
                                            <button
                                                onClick={() => setCanc(-1)}
                                                data-modal-hide="popup-modal"
                                                type="button"
                                                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                            >
                                                No, annulla
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Foto;
