import React from "react";
import PDF from "./Privacy.pdf";

function Privacy() {
    return (
        <div className="flex justify-center items-center w-full h-[90vh]">
            <object
                data={PDF}
                type="application/pdf"
                width="100%"
                height="100%"
                className="max-w-[70rem]"
            >
                <p>
                    Clicca qui per vedere il
                    <a href={PDF}>PDF!</a>
                </p>
            </object>
        </div>
    );
}

export default Privacy;
