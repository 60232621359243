import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import "react-quill/dist/quill.snow.css";

const ArticlePage = () => {
	const slug = useParams().id;
	const [data, setData] = useState({
		img: "",
		titolo: "",
		descr: "",
		testo: "",
		id: null,
	});
	const [loaded, setLoaded] = useState(false);

	const handleLoad = async () => {
		console.log(slug);
		const docRef = doc(db, "news", slug);
		const docSnap = await getDoc(docRef);
		setData(docSnap.data());
		console.log(docSnap.data());
		setLoaded(true);
	};

	useEffect(() => {
		if (!loaded) {
			handleLoad();
		} else {
			document.getElementById("testo").innerHTML = data.testo;
			document.getElementById("descr").innerHTML = data.descr;
		}
	});

	return (
		<>
			{loaded && (
				<div className="min-h-screen">
					<header className="bg-white border-b-2 border-gray-200">
						<div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
							<h1 className="md:text-6xl text-4xl text-center font-bold text-gray-900">
								{data.titolo}
							</h1>
							<p className="text-sm text-gray-500">Pubblicato il {data.date}</p>
						</div>
					</header>
					<main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
						<div className="px-4 py-6 flex flex-col justify-center items-center bg-gray-100 border border-gray-300 shadow-xl rounded-xl sm:rounded-lg">
							<img
								src={data.img}
								alt="Copertina dell'articolo"
								className="mb-6 w-1/2 rounded-lg"
							/>
							<div
								className="md:text-4xl text-3xl font-medium mb-4 place-self-center"
								id="descr"
							></div>
							<div className="" id="testo"></div>
						</div>
					</main>
				</div>
			)}
		</>
	);
};

export default ArticlePage;
