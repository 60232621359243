import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import IsLogged from "./IsLogged";

function ModNews() {
	if (!IsLogged()) {
		window.location.href = "/admin";
	}

	const [Data, setData] = useState([]);

	const [canc, setCanc] = useState(-1);

	const handleLoad = async () => {
		const querySnapshot = await getDocs(collection(db, "news"));
		const docs = querySnapshot.docs;
		const dati = docs.map((doc) => doc.data());
		const sorted = dati.sort((a, b) => b.date - a.date).reverse();
		setData(sorted);
		console.log(sorted);
		
	};
	useEffect(() => {
		handleLoad();
	}, []);

	const elimina = async (i) => {
		await deleteDoc(doc(db, "news", i));
		handleLoad();
		setCanc(-1);
	};

	return (
		<div className="mt-12 flex flex-col items-center justify-center">
			<div>
				<button
					onClick={() => window.history.back()}
					type="button"
					className="text-white bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-800"
				>
					Torna Indietro
				</button>
				<button
					onClick={() => (window.location.href = "/aggiunginews")}
					type="button"
					className="text-white bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-800"
				>
					Aggiungi
				</button>
			</div>
			<div className=" mt-20 px-24 flex justify-center flex-wrap">
				{Data.map((item, i) => {
					return (
						<div
							key={i}
							className="w-96 m-4 bg-gray-200 p-5 rounded-lg shadow-lg border border-gray-300 flex flex-col space-y-5 "
						>
							<div
								onClick={() => (window.location.href = "/modnews/" + item.id)}
								className="cursor-pointer flex justify-center"
							>
								<img className="rounded-lg h-24" src={item.img} alt="" />
							</div>
							<div
								onClick={() => (window.location.href = "/modnews/" + item.id)}
								className="flex cursor-pointer flex-col items-center justify-center"
							>
								<p className=" text-lg font-semibold">{item.titolo}</p>
								<p className="">{item.descr}</p>
							</div>
							<div className="place-self-center">
								<button
									className="cursor-pointer mr-1 w-20 bg-blue-500 text-white p-2 rounded-xl hover:bg-blue-700"
									onClick={() => (window.location.href = "/modnews/" + item.id)}
								>
									Modifica
								</button>
								<button
									className="cursor-pointer ml-1 w-20 bg-red-700 text-white p-2 rounded-xl hover:bg-red-800"
									onClick={() => setCanc(i)}
								>
									Cancella
								</button>
							</div>
						</div>
					);
				})}
			</div>
			{canc > -1 && (
				<div className="bg-gray-800 fixed w-full h-[100vh] top-0 bg-opacity-40">
					<div
						id="popup-modal"
						tabindex="-1"
						class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto max-h-full"
					>
						<div class="relative w-full max-w-md max-h-full">
							<div class="relative bg-white rounded-lg shadow">
								<button
									onClick={() => setCanc(-1)}
									type="button"
									class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
									data-modal-hide="popup-modal"
								>
									<svg
										aria-hidden="true"
										class="w-5 h-5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										></path>
									</svg>
									<span class="sr-only">Close modal</span>
								</button>
								<div class="p-6 text-center flex flex-col items-center w-full">
									<img src={Data[canc].url} alt="" className="w-48" />
									<h3 class="my-5 text-lg font-normal text-gray-900 ">
										Sei sicuro di voler cancellare questa news?
									</h3>
									<div>
										<button
											onClick={() => elimina(Data[canc].id)}
											data-modal-hide="popup-modal"
											type="button"
											class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
										>
											Si, sono sicuro
										</button>
										<button
											onClick={() => setCanc(-1)}
											data-modal-hide="popup-modal"
											type="button"
											class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
										>
											No, annulla
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default ModNews;
