import React from "react";

function HoverWbcm({ href }) {
	return (
		<div onClick={()=> window.open(href)} className="absolute cursor-pointer hidden md:block">
			<div className="bg-gray-400 bg-opacity-50 flex items-center justify-center w-[500px] rounded-lg h-[281.25px]">
				<div className="bg-blue-500 rounded-lg p-4 text-white font-medium">
					Per vedere la webcam in tempo reale clicca qui
				</div>
			</div>
		</div>
	);
}

export default HoverWbcm;
