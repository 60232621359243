import React from "react";

function Sentiero({ img, titolo, descrizione, link }) {
	const flags = !link.length > 0 ? false : true;

	return (
		<div className="bg-gray-200 p-5 rounded-md">
			<button onClick={(e)=>flags && window.open(link,"_blank")} rel="norefferer" >
				<img
					className="rounded-md"
					src={require("../../../Assets/" + img)}
					alt={titolo}
				/>
			</button>
			<h1 className="text-3xl my-5">{titolo}</h1>
			<p className="">{descrizione}</p>
		</div>
	);
}

export default Sentiero;
