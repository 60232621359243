import { motion } from "framer-motion";


const ChipTabs = ({selected, setSelected, tabs }) => {

	return (
		<div className="px-4 py-14  flex items-center flex-wrap gap-4">
			{tabs.map((tab) => (
				<Chip
					text={tab}
					selected={selected === tab}
					setSelected={setSelected}
					key={tab}
				/>
			))}
		</div>
	);
};

const Chip = ({ text, selected, setSelected }) => {
	return (
		<button
			onClick={() => setSelected(text)}
			className={`${
				selected
					? "text-white"
					: "text-black hover:text-slate-200 hover:bg-slate-700"
			} text-sm transition-colors px-2.5 py-0.5 rounded-md relative`}
		>
			<span className="relative z-10 text-2xl">{text}</span>
			{selected && (
				<motion.span
					layoutId="pill-tab"
					transition={{ type: "spring", duration: 0.5 }}
					className="absolute inset-0 z-0 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-md"
				></motion.span>
			)}
		</button>
	);
};

export default ChipTabs;
