import { motion } from "framer-motion";
import { useState } from "react";
import { FiMenu, FiArrowRight } from "react-icons/fi";
import InstaBtn from "../../Atoms/Buttons/InstaBtn";
import FBBtn from "../../Atoms/Buttons/FBBtn";
import YTBtn from "../../Atoms/Buttons/YTBtn";
import Dropdown from "../../Atoms/Dropdowns/Dropdown";

const NewNavbar = () => {
	return (
		<div className="bg-gray-50">
			<FlipNav />
		</div>
	);
};

const FlipNav = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<nav className="bg-white p-2 border-b-[1px] border-gray-200 flex items-center justify-between relative">
			<NavLeft setIsOpen={setIsOpen} />
			<NavRight />
			<NavMenu isOpen={isOpen} />
		</nav>
	);
};

const Logo = () => {
	return (
		<a href="/" className="flex items-center">
			<img
				src={require("../../../Assets/stemma1.jpg")}
				className=" md:h-16 h-10         mr-3"
				alt="Stemma"
			/>
			<span className="self-center md:text-5xl text-lg font-semibold whitespace-nowrap ">
				Mondrone
			</span>
			<img
				src={require("../../../Assets/stemma2.jpg")}
				className=" md:h-16 h-10         ml-3"
				alt="Stemma"
			/>
		</a>
	);
};

const NavLeft = ({ setIsOpen }) => {
	const paese = [
		{
			link: "/storia",
			titolo: "Storia di Mondrone",
			child: [],
		},
		{
			link: "/spaziaggregativi",
			titolo: "Spazi aggregativi",
			child: [
				{
					titolo: "Libera Biblioteca",
					link: "/spaziaggregativi/biblioteca",
				},
				{
					titolo: "Area sportiva",
					link: "/spaziaggregativi/areasportiva",
				},
				{
					titolo: "Giochi bimbi",
					link: "/spaziaggregativi/giochibimbi",
				},
			],
		},

		{
			link: "/tradizioni",
			titolo: "Tradizioni",
			child: [
				{ titolo: "Costumi locali", link: "/tradizioni/costumi" },
				{ titolo: "Ricette tipiche", link: "/tradizioni/ricette" },

			],
		},
		{
			link: "/natura",
			titolo: "Aspetti naturalistici",
			child: [
				{ titolo: "Flora", link: "/natura/flora" },
				{ titolo: "Fauna", link: "/natura/fauna" },
				{ titolo: "Geologia", link: "/natura/geologia" },
			],
		},

		{
			link: "/luoghi",
			titolo: "Luoghi",
			hasAchild: true,
			child: [
				{ titolo: "Piazza", link: "/luoghi/piazza" },
				{ titolo: "Piazza De Pinedo", link: "/luoghi/pinedo" },
				{ titolo: "Fonte del Pianard", link: "/luoghi/pianard" },
			],
		},
		{
			link: "/arrivareamondrone",
			titolo: "Arrivare a Mondrone",
			child: [],
		},
	];
	const webcam = [
		{ titolo: "Webcam di Mondrone", link: "/webcam/mondrone", child: [] },
		{
			titolo: "Altri paesi e rifugi",
			link: "/webcam/altripaesi",
			child: [],
		},
	];
	return (
		<div className="flex items-center gap-6">
			<motion.button
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95 }}
				className="block lg:hidden text-gray-950 text-2xl ml-1"
				onClick={() => setIsOpen((pv) => !pv)}
			>
				<FiMenu />
			</motion.button>
			<Logo />
			<NavLink text="News" link="/news" />
			<NavLinkDropDown title="Il Paese" link="/paese" elements={paese} />
			<NavLink text="Meteo" link="/meteo" />
			<NavLink text="Foto" link="/foto" />
			<NavLink text="Il Circolo Mondronese" link="/circolo" />
			<NavLinkDropDown title="Webcam" elements={webcam} />
		</div>
	);
};

const NavLink = ({ text, link }) => {
	return (
		<a
			href={link}
			rel="nofollow"
			className="hidden lg:block h-[30px] overflow-hidden font-medium"
		>
			<span className="flex items-center h-[30px]">{text}</span>
			<span className="flex items-center h-[30px] text-indigo-600">{text}</span>
		</a>
	);
};
const NavLinkDropDown = ({ title, link, elements }) => {
	return (
		<Dropdown
			className={"hidden md:block"}
			click={1}
			title={title}
			link={link}
			elements={elements}
		/>
	);
};

const NavRight = () => {
	return (
		<div className="flex items-center gap-4">
			<FBBtn />
			<InstaBtn />
			<YTBtn />
		</div>
	);
};

const NavMenu = ({ isOpen }) => {
	const paese = [
		{
			link: "/storia",
			titolo: "Storia di Mondrone",
			child: [],
		},
		{
			link: "/spaziaggregativi",
			titolo: "Spazi aggregativi",
			child: [
				{
					titolo: "Libera Biblioteca",
					link: "/spaziaggregativi/biblioteca",
				},
				{
					titolo: "Area sportiva",
					link: "/spaziaggregativi/areasportiva",
				},
				{
					titolo: "Giochi bimbi",
					link: "/spaziaggregativi/giochibimbi",
				},
			],
		},

		{
			link: "/tradizioni",
			titolo: "Tradizioni",
			child: [
				{ titolo: "Costumi locali", link: "/tradizioni/costumi" },
				{ titolo: "Ricette tipiche", link: "/tradizioni/ricette" },
			],
		},
		{
			link: "/natura",
			titolo: "Aspetti naturalistici",
			child: [
				{ titolo: "Flora", link: "/natura/flora" },
				{ titolo: "Fauna", link: "/natura/fauna" },
				{ titolo: "Geologia", link: "/natura/geologia" },
			],
		},

		{
			link: "/luoghi",
			titolo: "Luoghi",
			hasAchild: true,
			child: [
				{ titolo: "Piazza", link: "/luoghi/piazza" },
				{ titolo: "Piazza De Pinedo", link: "/luoghi/pinedo" },
				{ titolo: "Fonte del Pianard", link: "/luoghi/pianard" },
			],
		},
		{
			link: "/arrivareamondrone",
			titolo: "Arrivare a Mondrone",
			child: [],
		},
	];
	const webcam = [
		{ titolo: "Webcam di Mondrone", link: "/webcam/mondrone", child: [] },
		{
			titolo: "Altri paesi e rifugi",
			link: "/webcam/altripaesi",
			child: [],
		},
	];
	return (
		<motion.div
			variants={menuVariants}
			initial="closed"
			animate={isOpen ? "open" : "closed"}
			className="absolute p-4 bg-white shadow-lg left-0 right-0 top-16 z-30 origin-top flex flex-col gap-4"
		>
			<MenuLink text="News" link="/news" />
			<MenuLinkDropDown title="Il Paese" elements={paese} />
			<MenuLink text="Meteo" link="/meteo" />
			<MenuLink text="Foto" link="/foto" />
			<MenuLink text="Il Circolo Mondronese" link="/circolo" />
			<MenuLinkDropDown title="Webcam" elements={webcam} />
		</motion.div>
	);
};

const MenuLink = ({ text, link }) => {
	return (
		<motion.a
			variants={menuLinkVariants}
			rel="nofollow"
			href={link}
			className="h-[30px] overflow-hidden font-medium text-lg flex items-start gap-2"
		>
			<motion.span variants={menuLinkArrowVariants}>
				<FiArrowRight className="h-[30px] text-gray-950" />
			</motion.span>
			<motion.div whileHover={{ y: -30 }}>
				<span className="flex items-center h-[30px] text-gray-500">{text}</span>
				<span className="flex items-center h-[30px] text-indigo-600">
					{text}
				</span>
			</motion.div>
		</motion.a>
	);
};
const MenuLinkDropDown = ({ title, elements }) => {
	return <Dropdown click={1} title={title} elements={elements} />;
};

export default NewNavbar;

const menuVariants = {
	open: {
		scaleY: 1,
		transition: {
			when: "beforeChildren",
			staggerChildren: 0.1,
		},
	},
	closed: {
		scaleY: 0,
		transition: {
			when: "afterChildren",
			staggerChildren: 0.1,
		},
	},
};

const menuLinkVariants = {
	open: {
		y: 0,
		opacity: 1,
	},
	closed: {
		y: -10,
		opacity: 0,
	},
};

const menuLinkArrowVariants = {
	open: {
		x: 0,
	},
	closed: {
		x: -4,
	},
};
