import React from "react";

function PanchinaGigante() {
	return (
		<div className="my-12 md:px-24 px-6">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				la Panchina Gigante
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					La panchina gigante BB#315 è una delle attrazioni di Mondrone,
					installata su iniziativa di privati e grazie alla disponibilità di chi
					ha offerto il terreno su cui poggia, è stata inaugurata nell'agosto
					del 2023. Questa imponente installazione di colore rosso e blu si
					trova nei pressi del sentiero{" "}
					<a
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 hover:underline cursor-pointer"
						href="/al_ba"
					>
						Al_Ba
					</a>{" "}
					e offre una vista mozzafiato sull'
					<a
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 hover:underline cursor-pointer"
						href="/Uja"
					>
						Uja di Mondrone
					</a>
					, una delle cime più belle della Val d’Ala.
				</p>
				<div className="flex gap-7 md:flex-row flex-col items-center">
					<p className="mt-7">
						Realizzata secondo il progetto del "Big Bench Community Project", la
						panchina gigante di Mondrone è un simbolo di comunità e di amore per
						il territorio. La sua posizione strategica permette ai visitatori di
						godere di un panorama spettacolare, con l'Uja di Mondrone che
						sovrasta e incornicia il borgo montano. Sedersi su questa panchina è
						un'esperienza unica: la dimensione sovradimensionata
						dell'installazione offre una prospettiva nuova e affascinante del
						paesaggio, facendo sentire i visitatori come dei bambini che
						riscoprono il mondo con occhi nuovi.
					</p>
					<img
						className="max-w-full h-auto"
						src={require("../Assets/Paese/panchina 1 .jpg")}
						alt=""
					/>
				</div>
				<p className="mt-10">
					Il sentiero Al-Ba, che conduce alla panchina, è un percorso
					escursionistico molto apprezzato dagli amanti della natura e del
					trekking. Lungo il cammino, si attraversano boschi rigogliosi e prati
					fioriti, accompagnati dal suono rilassante dei ruscelli e dal canto
					degli uccelli. L'arrivo alla panchina gigante rappresenta il culmine
					di un'escursione piacevole e gratificante, offrendo un luogo ideale
					per una pausa, un picnic o semplicemente per contemplare la bellezza
					della natura circostante.
				</p>
				<p className="mt-7">
					L'inaugurazione della panchina gigante numero 315 è stata un evento
					significativo per la comunità di Mondrone. La cerimonia ha visto la
					partecipazione di residenti, escursionisti e turisti, tutti uniti
					dalla curiosità e dall'entusiasmo di scoprire questa nuova attrazione.
					Da allora, la panchina è diventata una meta molto frequentata, un
					punto di incontro e di condivisione, dove le persone possono
					scambiarsi storie, esperienze e sorrisi, immersi nella tranquillità
					delle montagne.
				</p>
				<p className="mt-7">
					La panchina gigante di Mondrone non è solo un punto panoramico, ma
					anche un simbolo di come le piccole comunità possano valorizzare e
					promuovere il loro territorio attraverso iniziative creative e
					coinvolgenti. Rappresenta un invito a fermarsi, a prendersi del tempo
					per ammirare la natura e a riscoprire il piacere delle cose semplici.
					La panchina gigante infatti è stata voluta dai promotori con la
					speranza che chiunque salga sulla panchina e si sieda ad ammirare il
					profilo delle montagne, ad ascoltare il respiro della natura
					millenaria, a godere del silenzio del luogo che la circonda, possa
					ritrovare in se stesso serenità e pace.
				</p>
			</div>
			<div className="flex flex-col md:flex-row gap-7 items-center justify-around mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"
						src={require("../Assets/Paese/panchina 2 .jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default PanchinaGigante;
