import React from "react";

function Flora() {
	return (
		<div className="my-12 md:px-24 px-12">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				Aspetti naturalistici
			</h1>
			<h2 className="text-center text-3xl uppercase font-semibold">Flora</h2>
			<div className="text-justify">
				<p className="mt-7">
					La vegetazione della Valle è stata nei secoli modificata in maniera
					evidente dall'attività umana. La presenza di insediamenti umani è
					evidenziata dall'assenza del bosco in fasce altitudinali che altrove
					sono caratterizzate da questo tipo di vegetazione; al posto degli
					alberi impiegati per la produzione di legname ha preso il sopravvento
					il pascolo che, grazie al continuo sfalcio e utilizzo da parte del
					bestiame, è diventato la caratteristica predominante del paesaggio.
				</p>
				<p className="mt-7">
					Il successivo abbandono e spopolamento della montagna che si è
					verificato nel corso del Novecento ha favorito il cambiamento delle
					caratteristiche della vegetazione.
				</p>
				<p className="mt-7">
					La successione altitudinale della vegetazione rispecchia la classica
					distribuzione delle Alpi. A partire dai fondovalle i boschi di
					castagno e rovere sono limitati alle quote più basse, lasciando presto
					il posto al faggio. I versanti destro e sinistro orografico
					all'altezza di Ala di Stura sono caratterizzati da belle faggete,
					boschi piuttosto fitti e ombrosi dove il sottobosco non riesce a
					svilupparsi.
				</p>
				<p className="mt-7">
					La fascia altitudinale successiva è occupata dalle conifere, tra le
					quali prevale il larice, che qui presenta una maggiore diffusione
					rispetto alla vicina Val di Viù. Sono presenti sporadiche pinete di
					impianto artificiale.
				</p>
				<p className="mt-7">
					La flora della valle è arricchita da specie vegetali tipiche. La
					Paeonia peregrina, rara e a rischio di estinzione, ha proprio in valle
					di Ala la località dov'è più abbondante in Piemonte. La si può
					osservare facilmente nel mese di giugno tra gli ultimi tornanti del
					Pian della Mussa e non lontano nel recinto delle prese d'acqua
					dell'Acquedotto di Torino. La Campanula elatines, relativamente
					frequente in valle di Ala fra gli 850 e i 2150 metri di quota, si può
					trovare salendo lungo il sentiero GTA ai laghi Verdi e sul sentiero
					che porta al lago Casias.
				</p>
				<p className="mt-7">
					A quote più elevate si incontrano le torbiere, ciò che resta del
					processo di interramento dei laghi alpini. In questo ambiente
					particolare, caratterizzato dalla presenza di acqua stagnante, le
					piante predominanti sono muschi e sfagni, accompagnati talvolta da
					piccole piante carnivore quali la pinguicola. Begli esempi si trovano
					al Pian della Ciavana, salendo al lago di Afframont e tra il Pian
					della Mussa e l'Alpe Saulera, sul sentiero che porta al Passo delle
					Mangioire.
				</p>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-3 items-center justify-items-center mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/04 campanula elatines.jpg")}
						alt=""
					/>
					<p>Campanula Elatines</p>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/peonia peregrina.jpg")}
						alt=""
					/>
					<p>Peonia peregrina</p>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-72"
						src={require("../../../Assets/foliage-ala-di-stura (1).jpg")}
						alt=""
					/>
					<p>Foliage autunnale</p>
				</div>
			</div>
		</div>
	);
}

export default Flora;
