import React, { useState } from "react";

import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import ImageUploader from "quill-image-uploader";
import Editor from "./EditorNews";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	setDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useLocation, useParams } from "react-router-dom";
import IsLogged from "./IsLogged";

Quill.register("modules/imageUploader", ImageUploader);

function CaricaNews({ nuovo }) {
	IsLogged();
	if (!IsLogged()) {
		window.location.href = "/admin";
	}
	const slug = useParams().id;

	const [init, setInit] = useState(null);
	const [testo, setTesto] = useState(nuovo ? "Scrivi qui..." : "");
	const [loaded, setLoaded] = useState(false);
	const [progress, setProgress] = useState(0);
	const [checked, setChecked] = useState(false);
	const [data, setData] = useState({
		img: "",
		titolo: "",
		descr: "",
		testo: "",
		id: null,
	});
	//console.log(init);

	const handleLoad = async () => {
		if (nuovo) {
			setInit(null);
			setLoaded(true);
		} else {
			console.log(slug);
			const docRef = doc(db, "news", slug);
			const docSnap = await getDoc(docRef);
			setData(docSnap.data());
			setTesto(docSnap.data().testo);
			console.log(docSnap.data());
			setLoaded(true);
		}
	};

	useEffect(() => {
		handleLoad();
	}, []);

	useEffect(() => {
		console.log("data", data);
		handleChange();
	}, [testo]);

	const handleChange = async () => {
		await setData({ ...data, testo: testo });
	};

	const handleImg = async (e) => {
		const img = e.target.files[0];
		console.log("img", img);
		const name = Date.now() + img.name;
		e.preventDefault();
		const storageRef = ref(storage, "news/" + name);
		const uploadTask = uploadBytesResumable(storageRef, img);

		uploadTask.on(
			"state_changed",

			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setProgress(Math.round(progress));
				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
					default:
						console.log("Upload is unknown");
						break;
				}
			},
			(error) => {
				switch (error.code) {
					case "storage/unauthorized":
						break;
					case "storage/canceled":
						break;

					case "storage/unknown":
						break;
					default:
						console.log("General Error");
						break;
				}
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					setData({ ...data, img: downloadURL });
				});
			}
		);
	};
	const formatDate = (date) => {
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	const handleSubmit = async (e) => {
		await handleChange();
		setData({ ...data, testo: testo });
		e.preventDefault();
		console.log("data", data);
		const oggi = Date.now().toString();
		if (nuovo) {
			await setDoc(doc(db, "news", oggi), {
				id: oggi,
				titolo: data.titolo,
				descr: data.descr,
				testo: data.testo,
				img: data.img,
				fissato: checked ? oggi : false,
				date: formatDate(new Date()),
				createdAt: new Date(),
			}).then((res) => {
				window.history.back();
			});
		} else {
			await setDoc(doc(db, "news", slug), {
				id: slug,
				titolo: data.titolo,
				descr: data.descr,
				testo: data.testo,
				img: data.img,
				fissato: checked ? oggi : false,
				date: data.date,
			}).then(() => {
				window.history.back();
			});
		}
	};

	const handleDelete = () => {
		if (nuovo) {
			window.location.href = "contenuti";
		} else {
			const request = { id: data.id };
		}
	};

	return (
		<>
			{loaded && (
				<form onSubmit={handleSubmit}>
					<div className="bg-cultured my-9 rounded-xl grid grid-cols-1 gap-8 p-9">
						<div className="flex justify-between">
							<div className="w-96">
								<label className="relative block overflow-hidden rounded-md  border-purple border px-3 pt-3 shadow-sm focus-within:border-orange focus-within:ring-1 focus-within:ring-orange">
									<input
										value={data.titolo}
										onChange={(e) =>
											setData({ ...data, titolo: e.currentTarget.value })
										}
										type="text"
										placeholder={"Titolo"}
										className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
									/>

									<span className="absolute left-3 top-2 -translate-y-1/2 text-xs  transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
										{"Titolo"}
									</span>
								</label>
							</div>
						</div>
						<div className="flex flex-col w-72 items-center  space-y-5">
							<label
								class="block mb-2  text-sm font-medium text-gray-900 "
								for="file_input"
							>
								Carica File
							</label>
							<input
								onChange={(e) => {
									//setImg(e.target.files[0]);
									handleImg(e);
								}}
								className="block w-full text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
								id="file_input"
								type="file"
							></input>
							{data.img.length > 0 ? (
								<img src={data.img} className="w-56" alt="" />
							) : (
								progress > 0 &&
								progress < 100 && <p>Caricamento: {progress}%</p>
							)}
						</div>
						<div className="w-[50%]">
							<label className="relative block overflow-hidden rounded-md  border-purple border px-3 pt-3 shadow-sm focus-within:border-orange focus-within:ring-1 focus-within:ring-orange">
								<textarea
									value={data.descr}
									onChange={(e) =>
										setData({ ...data, descr: e.currentTarget.value })
									}
									placeholder="Description"
									className="peer h-16 w-full border-none bg-transparent p-0 placeholder-transparent placeholder:top-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
								/>

								<span className="absolute left-3 top-2 -translate-y-1/2 text-xs  transition-all  peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
									Descrizione
								</span>
							</label>
						</div>
						<div className="flex place-self-center w-48"></div>
						<div className="w-full">
							<span className="-translate-y-1/2 text-sm transition-all  peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
								Testo
							</span>
							<Editor setTesto={setTesto} testo={testo} />
						</div>

						<div>
							<label htmlFor="">Fissa in alto</label>
							<input
								type="checkbox"
								onClick={() => setChecked(!checked)}
								checked={checked}
							></input>
						</div>
						<div className="">
							<button
								className="flex gap-3 items-center px-5 py-3  shadow-lg bg-blue-500 font-bold rounded-full transition-all duration-200 hover:fill-orange hover:bg-white hover:text-orange hover:border-[2px] hover:border-orange hover:scale-110"
								type={"submit"}
							>
								<p className="w-auto">{"Pubblica"}</p>
								<div className="w-8">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
										<path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
									</svg>
								</div>
							</button>
						</div>
					</div>
				</form>
			)}
		</>
	);
}

export default CaricaNews;

/*
<textarea
							value={data.testo}
							onChange={(e) =>
								setData({ ...data, testo: e.currentTarget.value })
							}
							placeholder="Messaggio"
							className="peer h-16 w-full border-none bg-transparent p-0 placeholder-transparent placeholder:top-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
						/>
*/
