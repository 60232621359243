import React, { useState } from "react";

function tagliaTestoDopoTotParole(testo, numParole) {
	var parole = testo.split(" ");

	if (parole.length > numParole) {
		return parole.slice(0, numParole).join(" ") + "...";
	}

	return testo;
}

function XCard({ img, titolo, clicked, setClicked, index, descrizione }) {
	return (
		<div
			className={`w-full grid grid-cols-3 rounded-md shadow-md cursor-pointer ${
				index === clicked
					? "bg-gray-200"
					: "bg-white-500 border border-gray-300"
			}`}
			onClick={() => {
				setClicked(index);
			}}
			key={index}
		>
			{" "}
			<div className="p-4 flex items-center">
				<img
					className="rounded-lg h-full object-cover"
					src={require("../../../Assets/" + img)}
					alt={titolo}
				/>
			</div>
			<div className="col-span-2 flex flex-col py-4 px-2 space-y-4">
				<h1 className="text-xl">{titolo}</h1>
				<p>{tagliaTestoDopoTotParole(descrizione, window.innerWidth > 768 ? 25 : 8)}</p>
			</div>
		</div>
	);
}

export default XCard;
