import React from "react";

function Biblioteca() {
	return (
		<div className="flex flex-col justify-center my-5 px-6 md:px-24">
			<h1 className="place-self-center text-center uppercase text-4xl md:text-6xl font-semibold my-4">
				libera Biblioteca di mondrone{" "}
			</h1>

			<div className="text-justify ">
				<div className="grid md:grid-flow-col gap-10 items-center">
					<div>
						<p className="my-2">
							Nata nell’estate del 2011, grazie alla generosità di soci e amici
							del Circolo Mondronese che hanno donato i primi volumi, la Libera
							Biblioteca è accessibile ai visitatori e frequentatori della
							frazione specialmente nel periodo estivo.
						</p>
						<p className="my-2">
							La Libera Biblioteca ha raggiunto una dotazione di oltre 2500
							volumi. Trovano spazio sugli scaffali libri di narrativa italiana
							e straniera, saggistica di vario genere, volumi dedicati alla
							montagna, oltre libri gialli, fantasy, di fantascienza, poesie e
							fumetti.
						</p>
						<p className="my-2">
							Uno spazio della biblioteca è dedicato ai ragazzi e ai bambini con
							la possibilità di leggere, disegnare, giocare. Si tratta di uno
							spazio a disposizione dei visitatori che permette e facilita
							occasioni di incontro, dialogo, gioco. Presso la Libera Biblioteca
							sono disponibili alcuni strumenti tecnologici per ricerche e
							approfondimenti e giochi di società.
						</p>
						<p className="my-2">
							L’accesso ai locali della biblioteca è libero. Nel periodo estivo
							è aperta da metà luglio a fine agosto tutto il giorno per tutti i
							giorni della settimana. Essendo ad accesso libero, l’impegno
							comune di tutti i frequentatori dei locali della biblioteca è il
							mantenimento in ordine degli spazi, dei giochi e dei libri.
						</p>
						<p className="my-2 font-semibold">
							Grazie al finanziamento ottenuto da Fondazione CRT con il progetto
							“Lib(e)ri in montagna” nella primavera 2024 i locali sono stati
							rinnovati e si è provveduto alla sostituzione della scaffalatura e
							al riordino dei volumi. Per saperne di più clicca{" "}
							<a
								className="text-blue-500 hover:underline cursor-pointer"
								href="/circolo/lib(e)ri"
							>
								qui
							</a>
							.
						</p>{" "}
					</div>
					<img
						className="h-72 aspect-auto justify-self-center md:justify-self-end"
						src={require("../../../Assets/Paese/libera biblioteca.jpg")}
						alt=""
					/>
				</div>
				<div className="col-span-full flex flex-col md:flex-row w-full items-center justify-between gap-12">
					<ol className="mt-7 list-decimal ">
						<h3 className="text-3xl">REGOLAMENTO D’USO DEI LIBRI</h3>
						<li className="my-2">
							L’uso dei libri non è vincolato al possesso di alcuna tessera di
							iscrizione
						</li>
						<li className="my-2">
							È disponibile qui (COLLEGARE FILE EXCEL con ELENCO) l’elenco dei
							libri
						</li>
						<li className="my-2">
							Si accettano donazioni di libri di ogni genere, escluse
							enciclopedie e simili per ragioni di spazio.
						</li>
						<li className="my-2">
							I libri donati possono essere lasciati sul tavolo della
							biblioteca.
						</li>
						<li className="my-2">
							I libri esposti sugli scaffali si possono prelevare liberamente
							previa compilazione sul REGISTRO DEL PRESTITO
						</li>
						<li className="my-2">
							I libri restituiti devono essere lasciati sul tavolo della
							biblioteca.
						</li>
						<li className="my-2">
							Al momento della restituzione del libro è necessario ricercare sul
							registro la riga del prelievo e completare con la data di
							riconsegna
						</li>
					</ol>
					<img
						className="max-w-full md:h-80"
						src={require("../../../Assets/Paese/libera biblioteca2.jpg")}
						alt=""
					/>
				</div>
			</div>
			<div className="flex flex-col md:flex-row gap-7 items-center justify-between mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"
						src={require("../../../Assets/Paese/BIBLIO 1 .jpg")}
						alt=""
					/>
					<p className="hidden">Campanula Elatines</p>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"
						src={require("../../../Assets/Paese/BIBLIO2 .jpg")}
						alt=""
					/>
					<p className="hidden">Campanula Elatines</p>
				</div>
			</div>
		</div>
	);
}

export default Biblioteca;
