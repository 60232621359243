import React from "react";
import Tel from "../../Atoms/Icons/Tel";
import Pharma from "../../Atoms/Icons/Pharma";
import Infermiera from "../../Atoms/Icons/Infermiera";

function Numeri() {
	return (
		<div className="border border-gray-300 rounded-xl shadow-xl h-[400px] md:w-[500px] w-[350px] justify-between p-4 gap-4">
			<h3 className="text-2xl mb-3 col-span-2 font-semibold">Numeri utili</h3>
			<div className="flex items-center justify-between ">
				<img src={require("../../../Assets/icons/syrup.png")} alt="" />
				<p>Farmacia di Ala di Stura</p>
				<a className="hover:underline text-end" href="tel:012355370">
					{" "}
					0123 55370
				</a>
			</div>
			<div className="my-2 bg-gray-500 h-[1px] w-full"></div>
			<div className="flex items-center justify-between ">
				<img src={require("../../../Assets/icons/farma.png")} alt="" />
				<p>Guardia medica Ceres</p>
				<a className="hover:underline text-end" href="tel:012353285">
					{" "}
					0123 53285
				</a>
			</div>
			<div className="my-2 bg-gray-500 h-[1px] w-full"></div>
			<div className="flex items-center justify-between ">
				<img src={require("../../../Assets/icons/ranger.png")} alt="" />
				<p> Guardia forestale Ceres</p>
				<a className="hover:underline text-end" href="tel:012353150">
					{" "}
					0123 53150
				</a>
			</div>
			<div className="my-2 hidden bg-gray-500 h-[1px] w-full"></div>
			<div className=" hidden items-center justify-between ">
				<img
					className="w-[50px]"
					src={require("../../../Assets/icons/red-cross-.png")}
					alt=""
				/>
				Croce Rossa
				<a className="hover:underline text-end" href="tel:012355370">
					{" "}
					0123 55370
				</a>
			</div>
			<div className="my-2 bg-gray-500 h-[1px] w-full"></div>
			<div className="flex items-center justify-between ">
				<img
					className="w-[50px]"
					src={require("../../../Assets/icons/firefighter.png")}
					alt=""
				/>
				Antincendio boschivo
				<a className="hover:underline text-end" href="tel:800807091">
					{" "}
					800 807091
				</a>
			</div>
			<div className="my-2 bg-gray-500 h-[1px] w-full"></div>
			<div className="flex items-center justify-between ">
				<img
					className="w-[50px]"
					src={require("../../../Assets/icons/kit.png")}
					alt=""
				/>
				<p className="ml-2 md:ml-0">Ambulatorio comunale di Ala di Stura</p>
				<a className="hover:underline text-end" href="tel:012355376">
					{" "}
					0123 55376
				</a>
			</div>
		</div>
	);
}

export default Numeri;
