import React from "react";

function Costumi() {
	return (
		<div className="flex flex-col justify-center items-center my-5">
			<h1 className="place-self-center uppercase md:text-6xl text-4xl font-semibold my-4">
				COSTUMI LOCALI{" "}
			</h1>
			<div className="px-12 grid md:grid-flow-col auto-cols-auto gap-10 items-center">
				<div className="text-justify">
					<h3 className="font-semibold text-xl">
						Vestito del costume femminile
					</h3>
					<p className="my-2">
						La tradizione vuole che il vestito del costume da donna sia cucito a
						mano e di stoffa pregiata, generalmente in taffetà ma può essere
						anche di lana.
					</p>
					<p className="my-2">
						Nella parte superiore la scollatura deve essere a v con pizzo bianco
						al collo, le maniche molto ricche di tessuto a chiudere con polsino
						stretto con bottoni.
					</p>
					<p className="my-2">
						Sulle spalle va appoggiato un foulard con fiori (stelle alpine,
						rododendri, rose…) o di lana o di seta con frange e va portato
						incrociato sul davanti e chiuso da una spilla. Al collo si indossa
						una croce appesa ad un collarino di velluto e sulla chiusura del
						collarino ci sono due code di seta se ad indossarlo è una donna
						sposata altrimenti no. Le code devono essere sovrapposte al foulard
						sulla schiena. La croce può essere in oro o argento: generalmente è
						in oro se indossato la donna sposata. Per tradizione l’abito è veste
						nuziale e la croce è dono del marito. Alle nozze può essere donata
						anche una catena lunga a cui al fondo era appeso un piccolo orologio
						che generalmente veniva riposto in un taschino interno del vestito.
					</p>
					<p className="my-2">
						Nella parte sottovita generalmente il vestito presenta numerose
						balze arricchite da velluto nero e pizzo Valenciennes nero, deve
						essere lungo fino alle caviglie ed essere rifinito con mussola nera.
					</p>
					<p className="my-2">
						A vita, sull’abito va portato un grembiule nero che si può anche
						ricamare e che ha inserti in pizzo. Il grembiule è chiuso da un
						fiocco di seta che ha le code lunghe se ad indossarlo è una donna
						sposata oppure senza code se la donna è da sposare.
					</p>
					<p className="my-2">
						Parte preziosa dell’abito è la cuffia che viene indossata sul capo.
						Deve essere fatta a mano tutta in pizzo valenciennes inamidato.{" "}
					</p>
					<p className="my-2">
						La parte centrale è a forma di scodellino e serve per infilare i
						capelli in modo che la cuffia rimanga fissa al capo. È di pizzo che
						contorna tutto lo scodellino assumendo ai lati la forma a
						fisarmonica alta di quattro strati e davanti e dietro una forma
						piatta. Attorno allo scodellino viene appoggiato un nastro di cotone
						stampato di 8 cm di altezza annodato con un fiocco sul davanti le
						code devono scendere sulla fronte una volta indossato.
					</p>
					<p className="my-2">
						Tipicamente la veste e i gioielli si tramandano.
					</p>
					<p className="my-2">
						La descrizione sopra è del vestito della festa. Nel quotidiano le
						donne indossavano sempre vestiti lunghi e grembiule con tasche di
						stoffa meno pregiata. Non si indossava la cuffia perché sulla testa
						portavano carichi di fieno, fogliame, etc.
					</p>
					<h3 className="font-semibold text-xl my-5">
						Vestito del costume maschile
					</h3>
					<p className="my-2">
						Pantalone in velluto o fustagno principalmente marrone, verde scuro
						e nero.
					</p>
					<p className="my-2">
						Camicia lunga bianca di tela ricamata per le cerimonie altrimenti
						no, di manica lunga con polsini e con colletto alla coreana.
					</p>
					<p className="my-2">
						Sopra la camicia si indossa il gilet di stoffa generalmente di
						fustagno.
					</p>
					<p className="my-2">
						Sopra il gilet si indossa la maglia bianca di lana di pecora fatta
						ai ferri con il bordo di canovaccio staccato e poi cucito dopo,
						ricamato di rosso a punto croce con fiori di montagna.
					</p>
					<div className="grid mt-10  md:grid-flow-col auto-cols-auto justify-evenly">
						<div className="grid grid-cols-1 justify-center">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/costumi 1971.jpg")}
								alt=""
							/>
							<p className="capitalize">costumi 1971</p>
						</div>
						<div className="grid grid-cols-1 justify-center">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/costumi 1987.jpg")}
								alt=""
							/>
							<p className="capitalize">costumi 1987</p>
						</div>
						<div className="grid grid-cols-1 justify-center">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/Costumi anni 20.jpg")}
								alt=""
							/>
							<p className="capitalize">costumi anni '20</p>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-8 justify-around justify-items-center">
					<div className="grid grid-cols-2 justify-items-center ">
						<div className="grid grid-cols-1 ">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/balze.jpg")}
								alt=""
							/>
							<p className="capitalize">balze</p>
						</div>
						<div className="grid grid-cols-1 ">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/croce.jpg")}
								alt=""
							/>
							<p className="capitalize">croce</p>
						</div>
					</div>

					<div className="grid grid-cols-1 ">
						<img
							className="h-64 aspect-auto"
							src={require("../../../Assets/Paese/cuffia.jpg")}
							alt=""
						/>
						<p className="capitalize">cuffia</p>
					</div>
					<div className="grid grid-cols-1 ">
						<img
							className="h-64 aspect-auto"
							src={require("../../../Assets/Paese/interno cuffia.jpg")}
							alt=""
						/>
						<p className="capitalize">interno cuffia</p>
					</div>
					<div className="grid grid-cols-2 justify-items-center ">
						<div className="grid grid-cols-1 ">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/polsino.jpg")}
								alt=""
							/>
							<p className="capitalize">polsino</p>
						</div>

						<div className="grid grid-cols-1 ">
							<img
								className="h-64 aspect-auto"
								src={require("../../../Assets/Paese/vestito.jpg")}
								alt=""
							/>
							<p className="capitalize">vestito</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Costumi;

/* */
