import React, { useState } from "react";
import XCard from "../../Components/Molecules/Card/XCard";
import Sentiero from "../../Components/Molecules/Sentieri/Sentiero";

function Panchina() {
	const [clicked, setClicked] = useState(0);
	const XCards = [
		{
			img: "Al_ba_percorso.png",
			titolo: "Il Sentiero",
			link: "",
			descrizione:
				"{Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est dignissimos tenetur doloremque minima quia voluptates. Quos excepturi delectus sequi, harum quae provident, esse est blanditiis cumque perspiciatis nemo, quidem accusamus.",
		},
		{
			img: "neve2.jpg",
			titolo: "Accessibilità e segnalazione",
			link: "",
			descrizione:
				"lo{Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est dignissimos tenetur doloremque minima quia voluptates. Quos excepturi delectus sequi, harum quae provident, esse est blanditiis cumque perspiciatis nemo, quidem accusamus.rem ",
		},
		{
			img: "neve2.jpg",
			titolo: "Bellezza naturale lungo il percorso",
			link: "",
			descrizione:
				"l{Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est dignissimos tenetur doloremque minima quia voluptates. Quos excepturi delectus sequi, harum quae provident, esse est blanditiis cumque perspiciatis nemo, quidem accusamus.",
		},
		{
			img: "neve2.jpg",
			titolo: "Attrattive lungo il sentiero",
			link: "",
			descrizione:
				"{Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est dignissimos tenetur doloremque minima quia voluptates. Quos excepturi delectus sequi, harum quae provident, esse est blanditiis cumque perspiciatis nemo, quidem accusamus.",
		},
		{
			img: "neve2.jpg",
			titolo: "Percorso e tappe del sentiero",
			link: "",
			descrizione:
				"{Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est dignissimos tenetur doloremque minima quia voluptates. Quos excepturi delectus sequi, harum quae provident, esse est blanditiis cumque perspiciatis nemo, quidem accusamus.",
		},
	];

	return (
		<div className="grid md:grid-cols-2 grid-cols-1 md:px-10">
			<div>
				<h1 className="text-4xl text-center md:text-left font-semibold my-5">
					Sentiero Panchina gigante
				</h1>
				<div className="flex flex-col px-5 space-y-3">
					{XCards.map((item, i) => {
						return (
							<XCard
								index={i}
								img={item.img}
								titolo={item.titolo}
								descrizione={item.descrizione}
								clicked={clicked}
								setClicked={setClicked}
							/>
						);
					})}
				</div>
			</div>
			<div className="mt-5">
				{XCards.map((item, i) => {
					if (i === clicked) {
						return (
							<Sentiero
								img={item.img}
								titolo={item.titolo}
								descrizione={item.descrizione}
								link={item.link}
							/>
						);
					}
				})}
			</div>
		</div>
	);
}

export default Panchina;
